import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import MetodoPageTemplate from '../../components/template-il-mio-metodo-di-lavoro';

const MetodoPage = ({ data }) => {
  const { hero, page } = data;
  const { title, tagTitle, metaDescription } = page;
  return (
    <Layout className="home" data={data}>
      <Seo
        title={tagTitle || title}
        ogImage={hero.childImageSharp.resize}
        description={metaDescription}
        data={data}
      />
      <MetodoPageTemplate hero={hero} {...page} />
    </Layout>
  );
};

export default MetodoPage;
export const query = graphql`
  query MetodoPageQuery {
    ...HeaderFragment
    ...SeoFragment
    hero: file(relativePath: { eq: "pexels-cottonbro-cut.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, layout: CONSTRAINED, placeholder: TRACED_SVG)
        resize(width: 1200) {
          src
          width
          height
        }
      }
    }
    page: ilMioMetodoDiLavoroPageJson {
      title
      tagTitle
      metaDescription
      intro
      titleStrumenti
      strumenti {
        title
        abstract
        slug
        image {
          childImageSharp {
            gatsbyImageData(width: 450, layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`;
