import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Img from './img-wrapper';
import Breadcrumb from './breadcrumb';

const MetodoPageTemplate = ({ hero, title, intro, titleStrumenti, strumenti }) => {
  const breadcrumb = [
    {
      link: '/chi-sono/',
      name: 'Chi sono'
    },
    { name: title }
  ];
  return (
    <>
      {hero && (
        <div className="section mt-0 p-0">
          <div className="hero position-relative">
            <GatsbyImage className="hero__img" image={getImage(hero)} alt="" />
            <div className="breadcrumb__wrapper">
              <div className="container-xl">
                <div className="row">
                  <div className="col-12">
                    <Breadcrumb className="breadcrumb--herox" breadcrumb={breadcrumb} />
                  </div>
                </div>
              </div>
            </div>
            <div className="hero__content pb-3">
              <div className="container-xl text-center pb-5">
                <div className="row">
                  <div className="col-12 col-md-10 offset-md-1 slider-hero__citation slider-hero__citation--small">
                    "Ogni intervento, indipendentemente dalla patologia trattata e dalle tecniche
                    applicate, si discosterà, almeno in parte, da tutti gli altri, proprio perché
                    sarà progettato ad hoc per quella specifica persona, come un vestito cucito su
                    misura."
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="section">
        <div className="container-xl">
          <div className="row">
            <div className="col">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12" dangerouslySetInnerHTML={{ __html: intro }}></div>
          </div>
        </div>
      </div>
      <div className="section mt-0">
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <h2>{titleStrumenti}</h2>
            </div>
          </div>
          <div className="row">
            {strumenti
              ? strumenti.map((strumento, i) => (
                  <div className="col-lg-6" key={i}>
                    <div className="row">
                      <div className="col-12">
                        <h3>{strumento.title}</h3>
                      </div>
                    </div>
                    <div className="row mb-4 mb-md-3">
                      <div className="col-sm-6 col-md-5">
                        <Img image={strumento.image} alt="cbt" />
                        <Link to={`/${strumento.slug}/`} className="d-none d-lg-inline-block mt-3">
                          Leggi di più
                        </Link>
                      </div>
                      <div className="col-sm-6 col-md-7 mt-4 mt-md-0">
                        <div
                          className="section__text-small"
                          dangerouslySetInnerHTML={{
                            __html: strumento.abstract
                          }}
                        ></div>
                        <Link to={`/${strumento.slug}/`} className="d-inline-block mb-4 d-lg-none">
                          Leggi di più
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MetodoPageTemplate;
